<template>
  <UiDialog :dialog="dialog" :max-width="500" scrollable>
    <template v-slot:header><UiTitle :title="name" /></template>
    <template v-slot:body>
      <div class="presencemanagement-individual-body">
        <v-data-table
          :headers="headers"
          :items="item[providers]"
          :loading="loading"
          item-key="externalId"
          class="presencemanagement-individual-body__table elevation-1"
          height="100%"
          hide-default-footer
          fixed-header
        >
          <template v-slot:[`item.updateDate`]="{ item }">
            <div class="tw-flex tw-items-center">
              <span>{{ `${dateUserLang(item.updateDate)}` }}</span>
            </div>
          </template>
          <template v-slot:[`item.modifiedFields`]="{ item }">
            <div v-if="item.modifiedFields.length > 0" class="tw-flex tw-items-center">
              <span class="tw-mr-1" v-for="(field, index) in item.modifiedFields" v-bind:key="`filed-${index}`"
                >{{ `${field}` }}
              </span>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.deletedFields`]="{ item }">
            <div v-if="item.deletedFields.length > 0" class="tw-flex tw-items-center">
              <span class="tw-mr-1" v-for="(field, index) in item.deletedFields" v-bind:key="`filed-${index}`"
                >{{ `${field}` }}
              </span>
            </div>
            <div v-else>-</div>
          </template>
        </v-data-table>
      </div></template
    >
    <template v-slot:actions>
      <v-btn type="submit" rounded text @click="$emit('close')">
        {{ $t('presencemanagement.modal.btn') }}
      </v-btn>
    </template>
  </UiDialog>
</template>

<script>
import { formatedDate } from '@/utils/date.util'
import UiTitle from '@/components/UI/Title.vue'
import UiDialog from '@/components/UI/Dialog.vue'

let initialData = () => ({
  confirm: false,
})

export default {
  name: 'PresenceManagementIndividual',
  components: {
    UiTitle,
    UiDialog,
  },
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    providers: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  data: () => initialData(),
  computed: {
    headers() {
      return [
        { text: 'Update date', value: 'updateDate' },
        { text: 'Modified fields', value: 'modifiedFields', sortable: false },
        { text: 'Deleted fields', value: 'deletedFields', sortable: false },
      ]
    },
  },
  methods: {
    confirmConsent() {
      this.$emit('confirm', this.item)
    },
    dateUserLang(date) {
      return formatedDate(date, 'L LT', this.locale)
    },
    resetData() {
      Object.assign(this.$data, initialData())
    },
  },
}
</script>

<style lang="scss">
.presencemanagement-individual-body {
  @apply tw-px-4 tw-py-6 sm:tw-px-6;
  &__table {
    @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;

    tr {
      &:hover {
        background: none !important;
      }
    }
  }
}
</style>
