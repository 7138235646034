<template>
  <div class="presence-management">
    <div>
      <div><ui-title class="tw-px-6 tw-pt-6" :title="$t('presencemanagement.title')"></ui-title></div>
      <v-radio-group class="tw-px-6" v-model="providers" hide-details>
        <v-radio v-for="provider in providersTable" :key="provider.type" :value="provider.type">
          <template v-slot:label>
            <div>
              {{ provider.label }}
              <span class="tw-ml-3" v-if="provider.type === 'gmb'">
                <v-icon>{{ icons.mdiGoogleMyBusiness }}</v-icon>
              </span>
              <span class="tw-ml-3" v-else>
                <v-icon>{{ icons.mdiFacebook }}</v-icon
                ><v-icon>{{ icons.mdiApple }}</v-icon
                ><v-icon>{{ icons.mdiWaze }}</v-icon>
              </span>
            </div>
          </template>
        </v-radio>
      </v-radio-group>
      <div class="tw-flex tw-flex-col sm:tw-flex-row sm:tw-p-6 tw-pt-4 tw-items-center">
        <v-text-field
          class=""
          v-model.trim="search"
          solo
          flat
          outlined
          :placeholder="$t('presencemanagement.actions.search')"
          dense
          :prepend-inner-icon="icons.mdiMagnify"
          hide-details
          @input="searchByString"
        />
        <v-btn-toggle mandatory class="sm:tw-ml-6 sm:tw-mt-0 tw-mt-4" v-model="filter" @change="filterChange">
          <v-btn class="tw-text-xs sm:tw-text-base" value="" color="primary">{{
            $t('presencemanagement.actions.all')
          }}</v-btn>
          <v-btn class="tw-text-xs sm:tw-text-base" value="new" color="primary"
            >{{ $t('presencemanagement.actions.new') }}
          </v-btn>
          <v-btn class="tw-text-xs sm:tw-text-base" value="closed" color="primary">
            {{ $t('presencemanagement.actions.closed') }}
          </v-btn>
          <v-btn class="tw-text-xs sm:tw-text-base" value="modified" color="primary">
            {{ $t('presencemanagement.actions.modified') }}
          </v-btn>
          <v-btn class="tw-text-xs sm:tw-text-base" value="error" color="primary">
            {{ $t('presencemanagement.actions.error') }}
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div class="tw-relative tw-h-full presence-management__main">
      <div class="tw-absolute tw-right-0 tw-bottom-0 tw-left-0 tw-top-0">
        <v-data-table
          :headers="headers"
          :items="currentProviders"
          :item-class="itemRowClass"
          :loading="dataLoading"
          item-key="externalId"
          class="elevation-1 presence-management__main__table"
          height="100%"
          :footer-props="{
            itemsPerPageOptions: [25, 50, 100],
          }"
          fixed-header
          @click:row="openIndividualView"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div class="tw-flex tw-items-center">
              <v-chip class="tw-mr-2 tw-h-4 tw-font-mono" small dark>{{ `${item.externalId}` }}</v-chip>
              <span>{{ `${item.name}` }}</span>
              <v-chip
                v-if="itemRowClass(item) != 'same'"
                class="tw-ml-2 tw-h-4 tw-font-mono presence-management__main__table__status-chip"
                small
                dark
                >{{ `${itemRowClass(item)}` }}</v-chip
              >
            </div>
            <div class="tw-text-xs">{{ `${item.address.street1} ${item.address.postcode} ${item.address.city}` }}</div>
          </template>
          <template v-slot:[`item.updateDate`]="{ item }">
            <div class="tw-flex tw-items-center">
              <span>{{ `${dateUserLang(item.updateDate)}` }}</span>
            </div>
          </template>
          <template v-slot:[`item.modifiedFields`]="{ item }">
            <div v-if="item.modifiedFields.length > 0" class="tw-flex tw-items-center">
              <span class="tw-mr-1" v-for="(field, index) in item.modifiedFields" v-bind:key="`filed-${index}`"
                >{{ `${field}` }}
              </span>
            </div>
            <div v-else>-</div>
          </template>
          <template v-slot:[`item.deletedFields`]="{ item }">
            <div v-if="item.deletedFields.length > 0" class="tw-flex tw-items-center">
              <span class="tw-mr-1" v-for="(field, index) in item.deletedFields" v-bind:key="`filed-${index}`"
                >{{ `${field}` }}
              </span>
            </div>
            <div v-else>-</div>
          </template>
        </v-data-table>
      </div>
    </div>
    <PresenceManagementIndividual
      ref="history"
      :dialog="individualOpened"
      :providers="providers"
      :item="currentLocation"
      :loading="opening"
      :name="currentLocationName"
      :locale="currentUserLocale"
      @close="closeModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getShortLocal } from '@/utils/string.util'
import { formatedDate } from '@/utils/date.util'
import { debounce } from '@/utils/func.util'
import { mdiFacebook, mdiApple, mdiWaze, mdiMagnify, mdiGoogleMyBusiness } from '@mdi/js'
import PresenceManagementIndividual from '@/components/Modal/PresenceManagementIndividual.vue'
import UiTitle from '@/components/UI/Title.vue'

export default {
  name: 'PresenceManagement',
  components: {
    PresenceManagementIndividual,
    UiTitle,
  },
  data: () => ({
    dataLoading: false,
    search: '',
    providers: 'evm',
    filter: '',
    individualOpened: false,
    opening: false,
    currentExternalId: '',
    currentLocationName: '',
    icons: {
      mdiFacebook,
      mdiApple,
      mdiWaze,
      mdiMagnify,
      mdiGoogleMyBusiness,
    },
  }),
  computed: {
    ...mapState({
      currentUserLocale: state => state.backoffice.currentUser.locale,
      presenceManagement: state => state.presencemanagement.presenceManagement,
      currentLocation: state => state.presencemanagement.currentLocation,
    }),
    headers() {
      return [
        { text: this.$t('presencemanagement.table.headers.locations'), value: 'name' },
        { text: this.$t('presencemanagement.table.headers.update'), value: 'updateDate' },
        { text: this.$t('presencemanagement.table.headers.modified'), value: 'modifiedFields', sortable: false },
        { text: this.$t('presencemanagement.table.headers.deleted'), value: 'deletedFields', sortable: false },
      ]
    },
    currentProviders() {
      return this.providers === 'evm' ? this.presenceManagement.evm : this.presenceManagement.gmb
    },
    providersTable() {
      return [
        { type: 'evm', label: this.$t('presencemanagement.evm.list.title') },
        { type: 'gmb', label: this.$t('presencemanagement.gmb.list.title') },
      ]
    },
  },
  async created() {
    this.dataLoading = true
    await this.getPresenceManagement({ size: 1000, page: 1, search: '', filters: 'status:' })
    this.dataLoading = false
  },
  methods: {
    ...mapActions({
      getPresenceManagement: 'presencemanagement/getPresenceManagement',
      getIndividualView: 'presencemanagement/getIndividualView',
    }),
    dateUserLang(date) {
      return formatedDate(date, 'L LT', getShortLocal(this.currentUserLocale))
    },
    itemRowClass(item) {
      if (item.status != 'same') {
        return item.status
      } else if (item.modifiedFields.length != 0 || item.deletedFields.length != 0) {
        return 'modified'
      }
      return 'same'
    },
    searchByString: debounce(async function (value) {
      this.dataLoading = true
      await this.getPresenceManagement({ size: 1000, page: 1, search: value })
      this.dataLoading = false
    }, 300),

    changeProvider() {
      this.individualOpened = false
      if (this.providers === 'evm') {
        this.providers = 'gmb'
      } else {
        this.providers = 'evm'
      }
    },

    async filterChange(value) {
      this.dataLoading = true
      this.individualOpened = false
      await this.getPresenceManagement({ size: 1000, page: 1, search: this.search, filters: `status:${value}` })
      this.dataLoading = false
    },

    async openIndividualView(item) {
      this.individualOpened = false
      this.currentLocationName = `${item.externalId}-${item.name}`
      await this.getIndividualView(item.externalId)
      this.individualOpened = true
    },

    closeModal() {
      this.individualOpened = false
    },
  },
}
</script>
<style lang="scss">
.presence-management {
  &__main {
    &__table {
      @apply tw-rounded-none tw-h-full tw-flex tw-flex-col;
      .new {
        .presence-management__main__table__status-chip {
          background: $green-atlantis !important;
        }
      }
      .modified {
        .presence-management__main__table__status-chip {
          background: $blue-dodger !important;
        }
      }
      tbody tr:hover {
        cursor: pointer;
      }
    }
  }
  .v-btn--active {
    .v-btn__content {
      color: $white;
    }
  }

  .closed {
    opacity: 0.5;
  }
}
</style>
